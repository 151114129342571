import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth";
import { useNavigate } from "react-router";
import axios from "axios";

import Header from "../../components/header";
import searchIcon from "../../assets/search.png";
import OrderBar from "../../components/orderBar";
import { PuffLoader } from "react-spinners";

const Aktivne = () => {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [aktivnost, setAktivnost] = useState(true);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const formatDate = (timestamp) => {
    const dateObj = new Date(timestamp);

    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = dateObj.getFullYear().toString();
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const filtered = orders?.filter((order) => {
    const { objekat, napomena, komercijalista } = order;
    const { ime, prezime } = komercijalista;
    const vrijeme = formatDate(order.vrijeme);
    const query = searchQuery.toLowerCase();

    return (
      objekat.toLowerCase().includes(query) ||
      napomena.toLowerCase().includes(query) ||
      vrijeme.toLowerCase().includes(query) ||
      ime.toLowerCase().includes(query) ||
      prezime.toLowerCase().includes(query)
    );
  });

  const aktivnostChange = async (orderId) => {
    try {
      await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/change-order-activity",
        {
          orderId,
        }
      );

      setOrders((prevData) => prevData.filter((order) => order.id !== orderId));
    } catch (err) {
      logout();
    }
  };

  useEffect(() => {
    const getAndSetOrders = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          "https://test-server-five-weld.vercel.app/api/admin/get-active-orders"
        );

        setOrders(response.data);
      } catch (err) {
        setError("Došlo je do greške.");
        logout();
      } finally {
        setLoading(false);
      }
    };

    getAndSetOrders();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  return (
    <div className="page-container">
      <Header />

      <div className="page-content">
        <div className="home-nav-div">
          <div className="search-input-div">
            <img src={searchIcon} className="search-input-img" />
            <input
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              placeholder="Pretraži..."
              className="search-input"
            />
          </div>
        </div>

        <div className="home-orders-div">
          {loading ? (
            <PuffLoader size={50} color={"#031623"} />
          ) : (
            filtered?.reverse()?.map((order) => {
              return (
                <OrderBar
                  aktivnostChange={aktivnostChange}
                  key={order.id}
                  order={order}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Aktivne;
