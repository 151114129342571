import React, { useState, useEffect, useContext } from "react";
import "./styles.css";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/auth";
import Select from "react-select";
import { ClipLoader } from "react-spinners";

import Header from "../../components/header";
import ProductCard from "../../components/productCard";
import searchIcon from "../../assets/search.png";
import binIcon from "../../assets/bin.png";
import deleteIcon from "../../assets/delete_icon.png";
import uploadIcon from "../../assets/export_image.png";
import Switch from "@mui/material/Switch";
import { uploadImage } from "../../helpers/uploadImage";
import axios from "axios";

const selectStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    fontSize: "16px",
    color: "var(--darkGray)",
    border: "1px solid var(--darkGray)",
    marginBottom: "16px",
    minHeight: "40px",
  }),
};

const Artikli = () => {
  const { isAuthenticated, token, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [brands, setBrands] = useState();
  const [products, setProducts] = useState();

  const [activeBrand, setActiveBrand] = useState("svi");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchProductQuery, setSearchProductQuery] = useState("");

  const [modalError, setModalError] = useState();
  const [activeModal, setActiveModal] = useState();
  const [loading, setLoading] = useState("izbrisiArtikal");
  const [brandName, setBrandName] = useState("");
  const [brandFile, setBrandFile] = useState(null);
  const [deleteId, setDeleteId] = useState();

  const [product, setProduct] = useState({
    file: null,
    ime: "",
    brand: "",
    sifra: "",
    cijena: "",
    opis: "",
    dostupnost: true,
  });
  const [editProduct, setEditProduct] = useState({});

  const brandOptions = brands?.map((brand) => ({
    value: brand.ime,
    label: brand.ime,
  }));

  const filteredBrands = brands?.filter((brand) =>
    brand.ime.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredProducts = products?.filter((product) => {
    const { ime, brand, sifra, opis } = product;
    const query = searchProductQuery.toLowerCase();

    return (
      ime.toLowerCase().includes(query) ||
      brand.toLowerCase().includes(query) ||
      sifra.toLowerCase().includes(query) ||
      opis.toLowerCase().includes(query)
    );
  });

  const handleProductDataChange = (event) => {
    const { name, value } = event.target;

    setProduct((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleProductDostupnostChange = () => {
    setProduct((prevData) => ({
      ...prevData,
      dostupnost: !prevData.dostupnost,
    }));
  };

  const handleProductBrandChange = (selectedOption) => {
    setProduct((prevData) => ({
      ...prevData,
      brand: selectedOption.value,
    }));
  };

  const handleEditProductDataChange = (event) => {
    const { name, value } = event.target;

    setEditProduct((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditProductDostupnostChange = () => {
    setEditProduct((prevData) => ({
      ...prevData,
      dostupnost: !prevData.dostupnost,
    }));
  };

  const handleEditProductBrandChange = (selectedOption) => {
    setEditProduct((prevData) => ({
      ...prevData,
      brand: selectedOption.value,
    }));
  };

  const closeModal = () => {
    setModalError("");
    setActiveModal(null);
    setBrandFile(null);
    setBrandName("");
    setProduct({
      file: null,
      ime: "",
      brand: "",
      sifra: "",
      cijena: "",
      opis: "",
      dostupnost: true,
    });
  };

  const handleModalContentClick = (event) => {
    event.stopPropagation();
  };

  function validateNumberInput(event) {
    event.target.value = event.target.value.replace(/\D/g, "");
  }

  function validateDecimalInput(event) {
    event.target.value = event.target.value.replace(/[^0-9.]/g, "");
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setBrandFile(file);
    };
    reader.readAsDataURL(file);
  };

  const handleProductFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setProduct((prevData) => ({
        ...prevData,
        file: file,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleEditProductFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setEditProduct((prevData) => ({
        ...prevData,
        file: file,
      }));
    };
    reader.readAsDataURL(file);
  };

  const addBrand = async () => {
    setModalError("");
    if (brandName === "") {
      setModalError("Ime brenda je neophodno.");
      return;
    }
    if (brandFile === null) {
      setModalError("Logo brenda je neophodan.");
      return;
    }
    try {
      setLoading("addBrand");
      const { url, identifier } = await uploadImage("brands", brandFile);

      axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/add-brand",
        {
          brand: {
            ime: brandName,
            url: url,
            identifier: identifier,
          },
          token: token,
        }
      );
      setBrands((prevState) => [
        ...prevState,
        { ime: brandName, url: url, identifier: identifier, id: "1" },
      ]);
      closeModal();
    } catch (err) {
    } finally {
      setLoading("");
    }
  };

  const addProduct = async () => {
    setModalError("");
    if (product.file === null) {
      setModalError("Slika artikla je neophodna.");
      return;
    }
    if (product.ime === "") {
      setModalError("Ime artikla je neophodno.");
      return;
    }
    if (product.brand === "") {
      setModalError("Brand artikla je neophodan.");
      return;
    }
    if (product.sifra === "") {
      setModalError("Sifra artikla je neophodna.");
      return;
    }
    if (product.cijena === "") {
      setModalError("Cijena artikla je neophodna.");
      return;
    }
    try {
      setLoading("addProduct");
      const { url, identifier } = await uploadImage("products", product.file);

      product.cijena = parseFloat(product.cijena);

      const response = await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/add-product",
        {
          product: product,
          url: url,
          identifier: identifier,
        }
      );

      setProducts((prevProducts) => [...prevProducts, response.data.data]);
      closeModal();
    } catch (err) {
      setModalError(err.response.data);
    } finally {
      setLoading("");
    }
  };

  const editProductHandler = async () => {
    setModalError("");
    if (editProduct.file === "") {
      setModalError("Slika artikla je neophodna.");
      return;
    }
    if (editProduct.ime === "") {
      setModalError("Ime artikla je neophodno.");
      return;
    }
    if (editProduct.brand === "") {
      setModalError("Brand artikla je neophodan.");
      return;
    }
    if (editProduct.sifra === "") {
      setModalError("Sifra artikla je neophodna.");
      return;
    }
    if (editProduct.cijena === "") {
      setModalError("Cijena artikla je neophodna.");
      return;
    }
    try {
      setLoading("izmijeniArtikal");
      let productUrl = editProduct.slika.url;
      let productIdentifier = editProduct.slika.identifier;

      if (editProduct.file) {
        await axios.post(
          "https://test-server-five-weld.vercel.app/api/admin/delete-image",
          {
            folder: "products",
            identifier: editProduct.slika.identifier,
          }
        );
        const { url, identifier } = await uploadImage(
          "products",
          editProduct.file
        );

        productUrl = url;
        productIdentifier = identifier;
      }

      const editedProduct = {
        ime: editProduct.ime,
        opis: editProduct.opis,
        slika: {
          url: productUrl,
          identifier: productIdentifier,
        },
        cijena: parseFloat(editProduct.cijena),
        dostupnost: editProduct.dostupnost,
        id: editProduct.id,
        sifra: editProduct.sifra,
        brand: editProduct.brand,
      };

      await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/update-product",
        {
          editedProduct: editedProduct,
        }
      );

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === editedProduct.id ? { ...editedProduct } : product
        )
      );

      closeModal();
    } catch (err) {
    } finally {
      setLoading("");
    }
  };

  const deleteProduct = async () => {
    try {
      await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/delete-image",
        {
          folder: "products",
          identifier: editProduct.slika.identifier,
        }
      );
      await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/delete-product",
        {
          productId: editProduct.id,
        }
      );
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== editProduct.id)
      );
      closeModal();
    } catch (err) {}
  };

  const deleteBrand = async () => {
    try {
      setLoading("deleteBrand");
      const response = await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/delete-brand",
        { deleteId }
      );
      window.location.reload();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading();
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading("brands");
        const brands = await axios.get(
          "https://test-server-five-weld.vercel.app/api/admin/get-brands"
        );

        const products = await axios.get(
          "https://test-server-five-weld.vercel.app/api/admin/get-products"
        );

        setProducts(products.data);
        setBrands(brands.data);
      } catch (err) {
        logout();
      } finally {
        setLoading("");
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  return (
    <>
      {activeModal === "dodajBrand" && (
        <div onClick={closeModal} className="modal-overlay">
          <div
            onClick={handleModalContentClick}
            className="modal-content animate__animated animate__zoomIn animate__faster"
          >
            <h1 className="modal-h">Dodaj brand</h1>

            <div className="modal-image-input-div">
              {brandFile ? (
                <img
                  className="modal-image-live"
                  src={URL.createObjectURL(brandFile)}
                />
              ) : (
                <>
                  <img className="modal-image-img" src={uploadIcon} />
                  <p className="modal-image-p">Logo brenda</p>
                  <input
                    className="modal-image-input"
                    onChange={handleFileChange}
                    type="file"
                    accept="image/*"
                  />
                </>
              )}
            </div>

            <input
              value={brandName}
              onChange={(e) => {
                setBrandName(e.target.value);
              }}
              placeholder="Ime brenda"
              className="modal-input"
            />

            {modalError && <p className="modal-error-p">{modalError}</p>}

            <div className="modal-btns-div">
              <div onClick={closeModal} className="modal-secondary-btn">
                Odkaži
              </div>
              <div onClick={addBrand} className="modal-primary-btn">
                {loading === "addBrand" ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Dodaj"
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {activeModal === "dodajArtikal" && (
        <div onClick={closeModal} className="modal-overlay">
          <div
            onClick={handleModalContentClick}
            className="modal-content animate__animated animate__zoomIn animate__faster"
          >
            <h1 className="modal-h">Dodaj artikal</h1>

            <div className="image-input-helper">
              <div className="modal-image-input-div artikal-image-input">
                {product.file ? (
                  <img
                    className="modal-image-live modal-product-image-live"
                    src={URL.createObjectURL(product.file)}
                  />
                ) : (
                  <>
                    {" "}
                    <img className="modal-image-img" src={uploadIcon} />
                    <p className="modal-image-p">Slika artikla</p>
                  </>
                )}

                <input
                  className="modal-image-input"
                  onChange={handleProductFileChange}
                  type="file"
                  accept="image/*"
                />
              </div>
            </div>

            <input
              value={product.ime}
              name="ime"
              onChange={handleProductDataChange}
              placeholder="Ime artikla"
              className="modal-input"
            />

            <Select
              options={brandOptions}
              styles={selectStyles}
              placeholder="Brand artikla"
              onChange={handleProductBrandChange}
            />

            <div className="modal-small-input-div">
              <input
                value={product.sifra}
                name="sifra"
                onChange={handleProductDataChange}
                className="modal-small-input"
                placeholder="Šifra artikla"
                onInput={validateNumberInput}
                maxLength={6}
              />
              <input
                value={product.cijena}
                name="cijena"
                onChange={handleProductDataChange}
                className="modal-small-input"
                placeholder="Cijena artikla"
                onInput={validateDecimalInput}
              />
            </div>

            <textarea
              value={product.opis}
              name="opis"
              onChange={handleProductDataChange}
              placeholder="Opis artikla"
              className="modal-textarea"
            />

            <div className="display-flex">
              <Switch
                onChange={handleProductDostupnostChange}
                checked={product.dostupnost}
              />
              <p className="">Dostupnost artikla</p>
            </div>

            {modalError && <p className="modal-error-p">{modalError}</p>}

            <div className="modal-btns-div">
              <div onClick={closeModal} className="modal-secondary-btn">
                Odkaži
              </div>
              <div onClick={addProduct} className="modal-primary-btn">
                {loading === "addProduct" ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Dodaj"
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {activeModal === "izmijeniArtikal" && (
        <div onClick={closeModal} className="modal-overlay">
          <div
            onClick={handleModalContentClick}
            className="modal-content animate__animated animate__zoomIn animate__faster"
          >
            <div onClick={deleteProduct} className="delete-icon-div">
              <img src={binIcon} className="delete-icon" />
            </div>

            <h1 className="modal-h">Izmijeni artikal</h1>

            <div className="image-input-helper">
              <div className="modal-image-input-div artikal-image-input">
                {editProduct?.file ? (
                  <img
                    className="modal-image-live modal-product-image-live"
                    src={URL.createObjectURL(editProduct.file)}
                  />
                ) : (
                  <img
                    className="modal-image-live modal-product-image-live"
                    src={editProduct.slika.url}
                  />
                )}

                <input
                  className="modal-image-input"
                  onChange={handleEditProductFileChange}
                  type="file"
                  accept="image/*"
                />
              </div>
            </div>

            <input
              value={editProduct.ime}
              name="ime"
              onChange={handleEditProductDataChange}
              placeholder="Ime artikla"
              className="modal-input"
            />

            <Select
              value={{ value: editProduct.brand, label: editProduct.brand }}
              options={brandOptions}
              styles={selectStyles}
              placeholder="Brand artikla"
              onChange={handleEditProductBrandChange}
            />

            <div className="modal-small-input-div">
              <input
                value={editProduct.sifra}
                name="sifra"
                onChange={handleEditProductDataChange}
                className="modal-small-input"
                placeholder="Šifra artikla"
                onInput={validateNumberInput}
                maxLength={6}
              />
              <input
                value={editProduct.cijena}
                name="cijena"
                onChange={handleEditProductDataChange}
                className="modal-small-input"
                placeholder="Cijena artikla"
                onInput={validateDecimalInput}
              />
            </div>

            <textarea
              value={editProduct.opis}
              name="opis"
              onChange={handleEditProductDataChange}
              placeholder="Opis artikla"
              className="modal-textarea"
            />

            <div className="display-flex">
              <Switch
                onChange={handleEditProductDostupnostChange}
                checked={editProduct.dostupnost}
              />
              <p className="">Dostupnost artikla</p>
            </div>

            {modalError && <p className="modal-error-p">{modalError}</p>}

            <div className="modal-btns-div ">
              <div onClick={closeModal} className="modal-secondary-btn">
                Odkaži
              </div>
              <div onClick={editProductHandler} className="modal-primary-btn">
                {loading === "izmijeniArtikal" ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Izmijeni"
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteId && (
        <div onClick={closeModal} className="modal-overlay">
          <div
            onClick={handleModalContentClick}
            className="modal-content animate__animated animate__zoomIn animate__faster"
          >
            <h1 className="modal-h">Izbriši brand?</h1>

            {modalError && <p className="modal-error-p">{modalError}</p>}

            <div className="modal-btns-div">
              <div
                onClick={() => {
                  setDeleteId(null);
                }}
                className="modal-secondary-btn"
              >
                Odkaži
              </div>
              <div onClick={deleteBrand} className="modal-primary-btn">
                {loading === "deleteBrand" ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Izbriši"
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="page-container">
        <Header />
        <div className="page-content">
          <div className="artikli-page-nav">
            <div
              onClick={() => setActiveModal("dodajBrand")}
              className="blue-btn"
            >
              Dodaj brand
            </div>
            <div className="search-input-div">
              <img src={searchIcon} className="search-input-img" />
              <input
                placeholder="Pretraži brandove..."
                className="search-input"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="blue-line" />

          <div className="artikli-brands-div">
            {loading === "brands" ? (
              <ClipLoader />
            ) : (
              <>
                <div
                  className={`brand-div animate__animated animate__fadeInUp animate__faster ${
                    activeBrand === "svi" ? "brand-div-active" : ""
                  }`}
                  onClick={() => setActiveBrand("svi")}
                >
                  <h1 className="all-brand-h">Svi artikli</h1>
                </div>
                {filteredBrands?.map((brand) => {
                  return (
                    <div
                      onClick={() => setActiveBrand(brand.ime)}
                      key={brand.id}
                      className={`brand-div animate__animated animate__fadeInUp animate__faster ${
                        activeBrand === brand.ime ? "brand-div-active" : ""
                      }`}
                    >
                      <img className="brand-img" src={brand.url} />
                      <img
                        onClick={() => {
                          setDeleteId(brand.id);
                        }}
                        className="delete-brand-icon"
                        src={deleteIcon}
                      />
                    </div>
                  );
                })}
              </>
            )}
          </div>

          <div className="blue-line" />

          <div className="artikli-page-nav">
            <div
              onClick={() => {
                setActiveModal("dodajArtikal");
              }}
              className="blue-btn"
            >
              Dodaj artikal
            </div>
            <div className="search-input-div">
              <img src={searchIcon} className="search-input-img" />
              <input
                placeholder="Pretraži artikle..."
                className="search-input"
                value={searchProductQuery}
                onChange={(e) => {
                  setSearchProductQuery(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="artikli-div">
            {loading === "brands" ? (
              <ClipLoader />
            ) : (
              filteredProducts?.map((product) => {
                if (activeBrand === "svi") {
                  return (
                    <ProductCard
                      setActiveModal={setActiveModal}
                      setEditProduct={setEditProduct}
                      product={product}
                      key={product.id}
                    />
                  );
                } else if (product.brand === activeBrand) {
                  return (
                    <ProductCard
                      setActiveModal={setActiveModal}
                      setEditProduct={setEditProduct}
                      product={product}
                      key={product.id}
                    />
                  );
                }
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Artikli;
