import React from "react";
import { Link } from "react-router-dom";

import editIcon from "../../assets/edit_icon.png";
import arrowRight from "../../assets/right_arrow.png";

const ObjekatBar = ({ objekat, setActiveModal, setEditObjekat }) => {
  return (
    <div className="order-bar-div  animate__animated animate__fadeInUp animate__faster">
      <div className="order-bar-info-div">
        <div className="order-bar-text-div">
          <h2 className="order-name-h">{objekat.ime}</h2>
        </div>

        <div className="order-bar-text-div">
          <div
            onClick={() => {
              setActiveModal("izmijeniObjekat");
              setEditObjekat(objekat);
            }}
            className="order-arrow-div"
          >
            <img className="order-arrow" src={editIcon} />
          </div>
          <Link to={`/objekti/${objekat.id}`} className="link">
            <div className="order-arrow-div">
              <img className="order-arrow" src={arrowRight} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ObjekatBar;
