import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import Hamburger from "hamburger-react";
import { AuthContext } from "../../context/auth";

import logo from "../../assets/dg_logo.png";

const Header = () => {
  const location = useLocation();
  const { logout } = useContext(AuthContext);

  const [activePage, setActivePage] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const setOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setActivePage(location.pathname);
  }, []);

  return (
    <>
      <div className="header-container">
        <img className="header-img" src={logo} />

        <div className="hambuer-icon-div">
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            duration={0.5}
            color="#FFFFFF"
          />
        </div>

        <div className="header-links-div">
          <Link className="link" to={"/"}>
            <div
              className={`header-link ${
                activePage === "/" && "header-link-active"
              }`}
            >
              Aktivne
            </div>
          </Link>
          <Link className="link" to={"/neaktivne"}>
            <div
              className={`header-link ${
                activePage === "/neaktivne" && "header-link-active"
              }`}
            >
              Neaktivne
            </div>
          </Link>
          <Link className="link" to={"/artikli"}>
            <div
              className={`header-link ${
                activePage === "/artikli" && "header-link-active"
              }`}
            >
              Artikli
            </div>
          </Link>
          <Link className="link" to={"/raspored"}>
            <div
              className={`header-link ${
                activePage === "/raspored" && "header-link-active"
              }`}
            >
              Raspored
            </div>
          </Link>
          <Link className="link" to={"/komercijalisti"}>
            <div
              className={`header-link ${
                activePage === "/komercijalisti" && "header-link-active"
              }`}
            >
              Komercijalisti
            </div>
          </Link>
          <Link className="link" to={"/objekti"}>
            <div
              className={`header-link ${
                activePage === "/objekti" && "header-link-active"
              }`}
            >
              Objekti
            </div>
          </Link>
          <div onClick={logout} className={`header-link`}>
            Log out
          </div>
        </div>
      </div>

      <div
        className={`header-container-mobile-close  ${
          isOpen && "header-container-mobile-open"
        }`}
      >
        <Link className="link" to={"/"}>
          <div
            className={`header-link-mobile ${
              activePage === "/" && "header-link-mobile-active"
            }`}
          >
            Aktivne
          </div>
        </Link>
        <Link className="link" to={"/neaktivne"}>
          <div
            className={`header-link-mobile ${
              activePage === "/neaktivne" && "header-link-mobile-active"
            }`}
          >
            Neaktivne
          </div>
        </Link>
        <Link className="link" to={"/artikli"}>
          <div
            className={`header-link-mobile ${
              activePage === "/artikli" && "header-link-mobile-active"
            }`}
          >
            Artikli
          </div>
        </Link>
        <Link className="link" to={"/raspored"}>
          <div
            className={`header-link-mobile ${
              activePage === "/raspored" && "header-link-mobile-active"
            }`}
          >
            Raspored
          </div>
        </Link>
        <Link className="link" to={"/komercijalisti"}>
          <div
            className={`header-link-mobile ${
              activePage === "/komercijalisti" && "header-link-mobile-active"
            }`}
          >
            Komercijalisti
          </div>
        </Link>
        <Link className="link" to={"/objekti"}>
          <div
            className={`header-link-mobile ${
              activePage === "/objekti" && "header-link-mobile-active"
            }`}
          >
            Objekti
          </div>
        </Link>
        <div onClick={logout} className={`header-link-mobile`}>
          Log out
        </div>
      </div>
    </>
  );
};

export default Header;
