import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Header from "../../components/header";

import notFountImage from "../../assets/404.png";

const NotFound = () => {
  return (
    <div className="page-container">
      <Header />
      <img className="not-found-image" src={notFountImage} />

      <Link to={"/"} className="link">
        <div className="blue-btn">Nazad na početnu</div>
      </Link>
    </div>
  );
};

export default NotFound;
