import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/auth";
import { useNavigate } from "react-router";

import Header from "../../components/header";
import searchIcon from "../../assets/search.png";
import binIcon from "../../assets/bin.png";
import ObjekatBar from "../../components/objekatBar";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const Objekti = () => {
  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);

  const [objekti, setObjekti] = useState();
  const [editObjekat, setEditObjekat] = useState("");
  const [loading, setLoading] = useState("");
  const [modalError, setModalError] = useState("");
  const [activeModal, setActiveModal] = useState("");
  const [objekat, setObjekat] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const filtered = objekti?.filter((objekat) => {
    const { ime } = objekat;
    const query = searchQuery.toLowerCase();

    return ime.toLowerCase().includes(query);
  });

  const closeModal = () => {
    setActiveModal(null);
    setModalError("");
    setObjekat("");
    setEditObjekat("");
  };

  const handleModalContentClick = (event) => {
    event.stopPropagation();
  };

  const handleEditModalDataChange = (event) => {
    setEditObjekat((prevState) => ({ ...prevState, ime: event.target.value }));
  };

  const addObjekat = async () => {
    try {
      setModalError("");
      setLoading("dodajObjekat");
      if (objekat === "") {
        setModalError("Ime objekta je neophodno.");
        return;
      }
      await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/add-objekat",
        {
          objekat: objekat,
        }
      );
      setObjekti((prevState) => [
        ...prevState,
        {
          ime: objekat,
          id: getRandomNumber(1, 100),
        },
      ]);
      closeModal();
    } catch (err) {
    } finally {
      setLoading("");
    }
  };

  const editObjekatHandler = async () => {
    try {
      setModalError("");
      setLoading("izmijeniObjekat");
      if (editObjekat === "") {
        setModalError("Ime objekta je neophodno.");
        return;
      }
      await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/edit-objekat",
        {
          editedObjekat: editObjekat,
        }
      );
      setObjekti((prevData) =>
        prevData.map((objekat) =>
          objekat.id === editObjekat.id ? { ...editObjekat } : objekat
        )
      );
      closeModal();
    } catch (err) {
    } finally {
      setLoading("");
    }
  };

  const deleteObjekat = async () => {
    try {
      await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/delete-objekat",
        {
          objekatId: editObjekat.id,
        }
      );
      setObjekti((prevData) =>
        prevData.filter((objekat) => objekat.id !== editObjekat.id)
      );
      closeModal();
    } catch (err) {}
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading("data");
        const response = await axios.get(
          "https://test-server-five-weld.vercel.app/api/admin/get-objekti",
          {}
        );

        setObjekti(response.data);
        closeModal();
      } catch (err) {
        logout();
      } finally {
        setLoading("");
      }
    };

    getData();
  }, []);

  return (
    <>
      {activeModal === "dodajObjekat" && (
        <div onClick={closeModal} className="modal-overlay">
          <div
            onClick={handleModalContentClick}
            className="modal-content animate__animated animate__zoomIn animate__faster"
          >
            <h1 className="modal-h">Dodaj objekat</h1>

            <input
              value={objekat}
              onChange={(e) => {
                setObjekat(e.target.value);
              }}
              placeholder="Ime objekta"
              className="modal-input"
            />

            {modalError && <p className="modal-error-p">{modalError}</p>}

            <div className="modal-btns-div">
              <div onClick={closeModal} className="modal-secondary-btn">
                Odkaži
              </div>
              <div onClick={addObjekat} className="modal-primary-btn">
                {loading === "dodajObjekat" ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Dodaj"
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {activeModal === "izmijeniObjekat" && (
        <div onClick={closeModal} className="modal-overlay">
          <div
            onClick={handleModalContentClick}
            className="modal-content animate__animated animate__zoomIn animate__faster"
          >
            <div onClick={deleteObjekat} className="delete-icon-div">
              <img src={binIcon} className="delete-icon" />
            </div>
            <h1 className="modal-h">Izmijeni objekat</h1>

            <input
              onChange={handleEditModalDataChange}
              value={editObjekat.ime}
              placeholder="Ime objekta"
              className="modal-input"
            />

            {modalError && <p className="modal-error-p">{modalError}</p>}

            <div className="modal-btns-div">
              <div onClick={closeModal} className="modal-secondary-btn">
                Odkaži
              </div>
              <div onClick={editObjekatHandler} className="modal-primary-btn">
                {loading === "izmijeniObjekat" ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Sačuvaj"
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="page-container">
        <Header />

        <div className="page-content">
          <div className="artikli-page-nav">
            <div
              onClick={() => {
                setActiveModal("dodajObjekat");
              }}
              className="blue-btn"
            >
              Dodaj objekat
            </div>

            <div className="search-input-div">
              <img src={searchIcon} className="search-input-img" />
              <input
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder="Pretraži..."
                className="search-input"
              />
            </div>
          </div>

          <div className="blue-line" />

          <div className="home-orders-div">
            {loading === "data" ? (
              <ClipLoader />
            ) : (
              filtered?.map((objekat) => {
                return (
                  <ObjekatBar
                    setActiveModal={setActiveModal}
                    setEditObjekat={setEditObjekat}
                    key={objekat.id}
                    objekat={objekat}
                  />
                );
              })
            )}
            {}
          </div>
        </div>
      </div>
    </>
  );
};

export default Objekti;
