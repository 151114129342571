import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../../components/header";
import searchIcon from "../../assets/search.png";
import axios from "axios";
import { PuffLoader } from "react-spinners";
import OrderBar from "../../components/orderBar";
import { AuthContext } from "../../context/auth";

const Komercijalista = () => {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [komercijalista, setKomercijalista] = useState();
  const [orders, setOrders] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const filtered = orders?.filter((order) => {
    const { objekat, napomena } = order;
    const query = searchQuery.toLowerCase();

    return (
      objekat.toLowerCase().includes(query) ||
      napomena.toLowerCase().includes(query)
    );
  });

  useEffect(() => {
    const getKomercijalista = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://test-server-five-weld.vercel.app/api/komercijalista/get-komercijalista-by-id",
          { id }
        );

        setKomercijalista(response.data);
      } catch (err) {
        logout();
      } finally {
        setLoading(false);
      }
    };

    getKomercijalista();
  }, []);

  useEffect(() => {
    const getKomercijalistaOrders = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://test-server-five-weld.vercel.app/api/komercijalista/get-orders-by-id",
          { id }
        );

        setOrders(response.data);
      } catch (err) {
        logout();
      } finally {
        setLoading(false);
      }
    };

    getKomercijalistaOrders();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  return (
    <div className="page-container">
      <Header />
      {loading ? (
        <PuffLoader />
      ) : (
        <div className="page-content">
          <div className="artikli-page-nav">
            <h1 className="page-heading">
              {komercijalista?.ime} {komercijalista?.prezime}
            </h1>

            <div className="search-input-div">
              <img src={searchIcon} className="search-input-img" />
              <input
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder="Pretraži..."
                className="search-input"
              />
            </div>
          </div>

          <div className="blue-line" />

          {filtered?.map((order) => {
            return <OrderBar key={order.id} order={order} />;
          })}
        </div>
      )}
    </div>
  );
};

export default Komercijalista;
