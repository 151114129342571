import React from "react";
import "./styles.css";

const ProductCard = ({ setEditProduct, product, setActiveModal }) => {
  return (
    <div className="product-card-div animate__animated animate__fadeInUp animate__faster	">
      <img className="product-card-img" src={product.slika.url} />
      <h2 className="product-card-name">{product.ime}</h2>
      <p className="product-card-id">{product.sifra}</p>
      <h3 className="product-card-price">{product.cijena.toFixed(2)}€</h3>
      <div className="product-card-status-div">
        <div
          className={`product-card-dot ${
            product.dostupnost === true ? "dot-green" : "dot-red"
          }`}
        />
        <p
          className={`product-card-status-p ${
            product.dostupnost === true ? "color-green" : "color-red"
          }`}
        >
          {product.dostupnost === true ? "Na stanju" : "Nema na stanju"}
        </p>
      </div>
      <div
        onClick={() => {
          setEditProduct(product);
          setActiveModal("izmijeniArtikal");
        }}
        className="product-card-btn"
      >
        Izmijenite artikal
      </div>
    </div>
  );
};

export default ProductCard;
