import React, { useState, useEffect } from "react";
import "./style.css";
import Header from "../../components/header";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import drag from "../../assets/menu.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Raspored = () => {
  const [brands, setBrands] = useState();
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState("");
  const [activeBrand, setActiveBrand] = useState();

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(products);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    console.log(items);
    setProducts(items);

    await axios.post(
      "https://test-server-five-weld.vercel.app/api/admin/update-products-order",
      {
        products: items,
      }
    );
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading("brands");
        const brands = await axios.get(
          "https://test-server-five-weld.vercel.app/api/admin/get-brands"
        );

        const products = await axios.get(
          "https://test-server-five-weld.vercel.app/api/admin/get-products"
        );

        setProducts(products.data);
        setBrands(brands.data);
        setActiveBrand(brands.data[0].ime);
      } catch (err) {
      } finally {
        setLoading("");
      }
    };

    getData();
  }, []);

  return (
    <div className="page-container">
      <Header />

      {loading === "brands" ? (
        <ClipLoader />
      ) : (
        <>
          <div className="raspored-brands-div">
            {brands?.map((brand) => {
              return (
                <div
                  key={brand.id}
                  onClick={() => {
                    setActiveBrand(brand.ime);
                  }}
                  className={`raspored-brand ${
                    activeBrand === brand.ime && "raspored-brand-active"
                  }`}
                >
                  {brand.ime}
                </div>
              );
            })}
          </div>

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="products">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="raspored-artikli-div"
                >
                  {products?.map((product, index) => {
                    if (product.brand === activeBrand) {
                      return (
                        <Draggable
                          key={product.sifra}
                          draggableId={product.sifra}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="raspored-artikal-div"
                            >
                              <img
                                className="raspored-artikal-drag-img"
                                src={drag}
                              />
                              <p className="raspored-artikla-p">
                                {product.ime} ({product.sifra})
                              </p>
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </div>
  );
};

export default Raspored;
