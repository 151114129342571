import React, { useState, useEffect, useContext } from "react";
import "./styles.css";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/auth";
import axios from "axios";

import Header from "../../components/header";
import searchIcon from "../../assets/search.png";
import binIcon from "../../assets/bin.png";
import KomercijalistaBar from "../../components/komercijalistaBar";
import { ClipLoader } from "react-spinners";

const Komercijalisti = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState("");
  const [modalError, setModalError] = useState("");
  const [activeModal, setActiveModal] = useState("");

  const [komercijalisti, setKomercijalisti] = useState();

  const [komercijalista, setKomercijalista] = useState({
    ime: "",
    prezime: "",
    username: "",
    password: "",
  });

  const [editKomercijalista, setEditKomercijalista] = useState({
    ime: "",
    prezime: "",
    username: "",
    password: "",
  });

  const handleKomercijalistaDataChange = (event) => {
    const { name, value } = event.target;

    setKomercijalista((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditKomercijalistaDataChange = (event) => {
    const { name, value } = event.target;

    setEditKomercijalista((prevData) => ({ ...prevData, [name]: value }));
  };

  const closeModal = () => {
    setActiveModal(null);
    setKomercijalista({
      ime: "",
      prezime: "",
      username: "",
      password: "",
    });
    setEditKomercijalista({
      ime: "",
      prezime: "",
      username: "",
      password: "",
    });
  };

  const handleModalContentClick = (event) => {
    event.stopPropagation();
  };

  const addKomercijalista = async () => {
    try {
      setLoading("dodajKomercijalistu");
      if (komercijalista.ime === "") {
        setModalError("Ime komercijaliste je neophodno.");
        return;
      }
      if (komercijalista.prezime === "") {
        setModalError("Prezime komercijaliste je neophodno.");
        return;
      }
      if (komercijalista.username === "") {
        setModalError("Username komercijaliste je neophodan.");
        return;
      }
      if (komercijalista.password === "") {
        setModalError("Password komercijaliste je neophodan.");
        return;
      }
      if (komercijalista.password < 8) {
        setModalError(
          "Password komercijaliste mora biti imati 8 ili vise slova."
        );
        return;
      }
      await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/add-komercijalista",
        {
          ...komercijalista,
        }
      );
      setKomercijalisti((prevState) => [
        ...prevState,
        {
          ime: komercijalista.ime,
          prezime: komercijalista.prezime,
          username: komercijalista.username,
          passwod: komercijalista.password,
          id: getRandomNumber(1, 100),
        },
      ]);
      closeModal();
    } catch (err) {
    } finally {
      setLoading("");
    }
  };

  const editKomercijalistaHandler = async () => {
    try {
      setLoading("izmijeniKomercijalistu");
      if (editKomercijalista.ime === "") {
        setModalError("Ime komercijaliste je neophodno.");
        return;
      }
      if (editKomercijalista.prezime === "") {
        setModalError("Prezime komercijaliste je neophodno.");
        return;
      }
      if (editKomercijalista.username === "") {
        setModalError("Username komercijaliste je neophodan.");
        return;
      }
      if (editKomercijalista.password === "") {
        setModalError("Password komercijaliste je neophodan.");
        return;
      }
      if (editKomercijalista.password < 8) {
        setModalError(
          "Password komercijaliste mora biti imati 8 ili vise slova."
        );
        return;
      }
      await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/edit-komercijalista",
        {
          editedKomercijalista: editKomercijalista,
        }
      );
      setKomercijalisti((prevData) =>
        prevData.map((komercijalista) =>
          komercijalista.id === editKomercijalista.id
            ? { ...editKomercijalista }
            : komercijalista
        )
      );
      closeModal();
    } catch (err) {
    } finally {
      setLoading("");
    }
  };

  const deleteKomercijalistu = async () => {
    try {
      await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/delete-komercijalista",
        {
          komercijalistaId: editKomercijalista.id,
        }
      );
      setKomercijalisti((prevData) =>
        prevData.filter(
          (komercijalista) => komercijalista.id !== editKomercijalista.id
        )
      );
      closeModal();
    } catch (err) {}
  };

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const filtered = komercijalisti?.filter((komercijalista) => {
    const { ime, prezime } = komercijalista;
    const query = searchQuery.toLowerCase();

    return (
      ime.toLowerCase().includes(query) || prezime.toLowerCase().includes(query)
    );
  });

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading("data");
        const response = await axios.get(
          "https://test-server-five-weld.vercel.app/api/admin/get-komercijaliste"
        );

        setKomercijalisti(response.data);
      } catch (err) {
        logout();
      } finally {
        setLoading("");
      }
    };

    getData();
  }, []);

  return (
    <>
      {activeModal === "dodajKomercijalistu" && (
        <div onClick={closeModal} className="modal-overlay">
          <div
            onClick={handleModalContentClick}
            className="modal-content animate__animated animate__zoomIn animate__faster"
          >
            <h1 className="modal-h">Dodaj komercijalistu</h1>

            <input
              value={komercijalista.ime}
              name="ime"
              placeholder="Ime"
              className="modal-input"
              onChange={handleKomercijalistaDataChange}
            />
            <input
              value={komercijalista.prezime}
              name="prezime"
              placeholder="Prezime"
              className="modal-input"
              onChange={handleKomercijalistaDataChange}
            />
            <input
              value={komercijalista.username}
              name="username"
              placeholder="Username"
              className="modal-input"
              onChange={handleKomercijalistaDataChange}
            />
            <input
              value={komercijalista.password}
              name="password"
              placeholder="Password"
              className="modal-input"
              onChange={handleKomercijalistaDataChange}
            />
            {modalError && <p className="modal-error-p">{modalError}</p>}

            <div className="modal-btns-div">
              <div onClick={closeModal} className="modal-secondary-btn">
                Odkaži
              </div>
              <div onClick={addKomercijalista} className="modal-primary-btn">
                {loading === "dodajKomercijalistu" ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Dodaj"
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {activeModal === "izmijeniKomercijalistu" && (
        <div onClick={closeModal} className="modal-overlay">
          <div
            onClick={handleModalContentClick}
            className="modal-content animate__animated animate__zoomIn animate__faster"
          >
            <div onClick={deleteKomercijalistu} className="delete-icon-div">
              <img src={binIcon} className="delete-icon" />
            </div>
            <h1 className="modal-h">Izmijeni komercijalistu</h1>

            <input
              value={editKomercijalista.ime}
              name="ime"
              placeholder="Ime"
              className="modal-input"
              onChange={handleEditKomercijalistaDataChange}
            />
            <input
              value={editKomercijalista.prezime}
              name="prezime"
              placeholder="Prezime"
              className="modal-input"
              onChange={handleEditKomercijalistaDataChange}
            />
            <input
              value={editKomercijalista.username}
              name="username"
              placeholder="Username"
              className="modal-input"
              onChange={handleEditKomercijalistaDataChange}
            />
            <input
              value={editKomercijalista.password}
              name="password"
              placeholder="Password"
              className="modal-input"
              onChange={handleEditKomercijalistaDataChange}
            />
            {modalError && <p className="modal-error-p">{modalError}</p>}

            <div className="modal-btns-div">
              <div onClick={closeModal} className="modal-secondary-btn">
                Odkaži
              </div>
              <div
                onClick={editKomercijalistaHandler}
                className="modal-primary-btn"
              >
                {loading === "izmijeniKomercijalistu" ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Sačuvaj"
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="page-container">
        <Header />
        <div className="page-content">
          <div className="artikli-page-nav">
            <div
              onClick={() => {
                setActiveModal("dodajKomercijalistu");
              }}
              className="blue-btn"
            >
              Dodaj komercijalistu
            </div>

            <div className="search-input-div">
              <img src={searchIcon} className="search-input-img" />
              <input
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder="Pretraži..."
                className="search-input"
              />
            </div>
          </div>

          <div className="blue-line" />

          <div className="home-orders-div">
            {loading === "data" ? (
              <ClipLoader />
            ) : (
              filtered?.map((komercijalista) => {
                return (
                  <KomercijalistaBar
                    setEditKomercijalista={setEditKomercijalista}
                    setActiveModal={setActiveModal}
                    key={komercijalista.id}
                    komercijalista={komercijalista}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Komercijalisti;
