import React, { useState } from "react";
import "./styles.css";

import arrow from "../../assets/arrow.png";

const OrderBar = ({ order, aktivnostChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const totalAmount = order.items.reduce((total, artikal) => {
    return total + artikal.cijena * artikal.kolicina;
  }, 0);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const formatDate = (timestamp) => {
    const dateObj = new Date(timestamp);

    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = dateObj.getFullYear().toString();
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  return (
    <div className="order-bar-div">
      <div className="order-bar-info-div">
        <div className="order-bar-text-div">
          <h3 className="order-number-h">#{order.index}</h3>
          <h2 className="order-name-h">
            {order.komercijalista.ime} za {order.objekat}
          </h2>
          <div
            className={`order-dot ${
              order.aktivnost === true ? "dot-green" : "dot-red"
            }`}
          />
        </div>

        <div className="order-bar-text-div">
          <p className="order-date-p">{formatDate(order.vrijeme)}</p>
          <h2 className="order-price-p">{totalAmount.toFixed(2)}€</h2>
          <div onClick={handleOpen} className="order-arrow-div">
            <img
              className={`order-arrow ${isOpen === true && "arrow-rotated"}`}
              src={arrow}
            />
          </div>
        </div>
      </div>

      <div
        className={`order-bar-content-div ${
          isOpen === true && "order-bar-content-div-open"
        }`}
      >
        {order?.items.map((artikal) => {
          const totalPrice = artikal.kolicina * artikal.cijena;

          return (
            <div key={artikal.id} className="order-product-bar">
              <div className="order-bar-text-div">
                <h3 className="order-product-name">
                  {artikal.ime} ({artikal.sifra})
                </h3>
                <p className="order-product-amount">x{artikal.kolicina}</p>
              </div>

              <p className="order-product-price">
                {artikal.cijena}€ / {totalPrice.toFixed(2)}€
              </p>
            </div>
          );
        })}

        <div className="order-product-line" />

        <p className="order-total-amount-mobile">{totalAmount.toFixed(2)}€</p>

        <div className="order-bar-note-div">
          {order.napomena ? order.napomena : "Ova porudžbina nema napomenu."}
        </div>

        {aktivnostChange && (
          <div
            onClick={() => {
              aktivnostChange(order.id);
            }}
            className="order-bar-btn"
          >
            {order.aktivnost ? "Označi kao neaktivnu." : "Označi kao aktivnu."}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderBar;
