import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";
import { uuidv4 } from "@firebase/util";
import imageCompression from "browser-image-compression";

export const uploadImage = async (url, file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 800,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);

    const imageIdentifier = uuidv4();

    const imageRef = ref(storage, `${url}/${imageIdentifier}`);

    await uploadBytes(imageRef, compressedFile);

    const imageUrl = await getDownloadURL(imageRef);

    return { url: imageUrl, identifier: imageIdentifier };
  } catch (err) {
    console.log(err);
  }
};
