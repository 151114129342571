import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";

import Home from "./pages/home";
import Artikli from "./pages/artikli";
import Komercijalisti from "./pages/komercijalisti";
import Login from "./pages/login";
import Komercijalista from "./pages/komercijalista";
import Objekti from "./pages/objekti";
import Objekat from "./pages/objekat";
import NotFound from "./pages/404";
import Raspored from "./pages/raspored";
import Aktivne from "./pages/aktivne/Aktivne";
import Neaktivne from "./pages/neaktivne/Neaktivne";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<Aktivne />} />
        <Route path="/neaktivne" element={<Neaktivne />} />
        <Route path="/artikli" element={<Artikli />} />
        <Route path="/raspored" element={<Raspored />} />
        <Route path="/komercijalisti" element={<Komercijalisti />} />
        <Route path="/komercijalisti/:id" element={<Komercijalista />} />
        <Route path="/objekti" element={<Objekti />} />
        <Route path="/objekti/:id" element={<Objekat />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
