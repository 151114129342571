import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import logo from "../../assets/dg_logo.png";

const Login = () => {
  const navigate = useNavigate();
  const { authenticate, isAuthenticated } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const loginHandler = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://test-server-five-weld.vercel.app/api/admin/admin-login",
        {
          username: username,
          password: password,
        }
      );

      if (response.data.message === "login-success") {
        navigate("/");
        authenticate(response.data.token);
      } else {
        setError(response.data.error);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <div className="page-container">
      <div className="login-header-container">
        <img src={logo} className="login-header-logo" />
      </div>

      <div className="login-form">
        <h1 className="login-form-h">Welcome back!</h1>
        <p className="login-form-p">Please enter your username and password.</p>
        <label className="login-form-label">Username</label>
        <input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="login-form-input"
          placeholder="Username"
        />
        <label className="login-form-label">Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-form-input"
          placeholder="Password"
        />
        {error && <p className="login-form-error-p">{error}</p>}
        <div onClick={loginHandler} className="login-form-btn">
          Continue
        </div>
      </div>
    </div>
  );
};

export default Login;
