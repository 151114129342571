import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

import editIcon from "../../assets/edit_icon.png";
import arrowRight from "../../assets/right_arrow.png";
import komercijalistaPlaceholder from "../../assets/komercijalista_placeholder.png";

const KomercijalistaBar = ({
  setEditKomercijalista,
  komercijalista,
  setActiveModal,
}) => {
  const imeFirstLetter = komercijalista.ime.charAt(0).toUpperCase();
  const prezimeFirstLetter = komercijalista.prezime.charAt(0).toUpperCase();

  return (
    <div className="order-bar-div  animate__animated animate__fadeInUp animate__faster">
      <div className="order-bar-info-div">
        <div className="order-bar-text-div">
          <div className="komercijalista-pfp">{`${imeFirstLetter}${prezimeFirstLetter}`}</div>
          <div className="order-name-h">
            {komercijalista.ime} {komercijalista.prezime}
          </div>
        </div>

        <div className="order-bar-text-div">
          <div
            onClick={() => {
              setEditKomercijalista(komercijalista);
              setActiveModal("izmijeniKomercijalistu");
            }}
            className="order-arrow-div"
          >
            <img className="order-arrow" src={editIcon} />
          </div>
          <Link to={`/komercijalisti/${komercijalista.id}`} className="link">
            <div className="order-arrow-div">
              <img className="order-arrow" src={arrowRight} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default KomercijalistaBar;
