import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "axios";

import Header from "../../components/header";
import searchIcon from "../../assets/search.png";
import { PuffLoader } from "react-spinners";
import OrderBar from "../../components/orderBar";
import { AuthContext } from "../../context/auth";

const Objekat = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [objekat, setObjekat] = useState();
  const [loading, setLoading] = useState(false);
  const [objekatOrders, setObjekatOrders] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const formatDate = (timestamp) => {
    const dateObj = new Date(timestamp);

    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = dateObj.getFullYear().toString();
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const filtered = objekatOrders?.filter((order) => {
    const { objekat, napomena, komercijalista } = order;
    const { ime, prezime } = komercijalista;
    const vrijeme = formatDate(order.vrijeme);
    const query = searchQuery.toLowerCase();

    return (
      objekat.toLowerCase().includes(query) ||
      napomena.toLowerCase().includes(query) ||
      vrijeme.toLowerCase().includes(query) ||
      ime.toLowerCase().includes(query) ||
      prezime.toLowerCase().includes(query)
    );
  });

  useEffect(() => {
    const getObjekat = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://test-server-five-weld.vercel.app/api/komercijalista/get-objekat-by-id",
          { id }
        );

        setObjekat(response.data);
      } catch (err) {
        logout();
      } finally {
        setLoading(false);
      }
    };

    getObjekat();
  }, []);

  useEffect(() => {
    const getObjekatOrders = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://test-server-five-weld.vercel.app/api/komercijalista/get-orders-by-objekat",
          { objekatName: objekat.ime }
        );

        setObjekatOrders(response.data);
      } catch (err) {
        logout();
      } finally {
        setLoading(false);
      }
    };

    if (objekat) {
      getObjekatOrders();
    }
  }, [objekat]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  return (
    <div className="page-container">
      <Header />
      {loading ? (
        <PuffLoader />
      ) : (
        <div className="page-content">
          <div className="artikli-page-nav">
            <h1 className="page-heading">{objekat?.ime}</h1>

            <div className="search-input-div">
              <img src={searchIcon} className="search-input-img" />
              <input
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder="Pretraži..."
                className="search-input"
              />
            </div>
          </div>

          <div className="blue-line" />

          {filtered?.map((order) => {
            return <OrderBar key={order.id} order={order} />;
          })}
        </div>
      )}
    </div>
  );
};

export default Objekat;
